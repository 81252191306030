import * as React from "react";
import { GraphQLProps } from "../../models/GraphQLProps";
import { FrontMatter } from "../../templates/model";
import {
	IoLogoGithub,
	IoMdBook,
	IoLogoYoutube,
	IoMdAppstore,
	IoMdLink,
} from "react-icons/io";
import { IconType } from "react-icons";
import "@themes/pill-button.scss";

export type SupportedPillButtonIcon =
	| "github"
	| "video"
	| "demo"
	| "googlePlay";

export interface PillButtonRowProps
	extends Partial<Pick<FrontMatter, SupportedPillButtonIcon>> {}

interface PillButtonProps {
	IconClass: IconType;
	text: string;
	link: string;
	openNewTab?: boolean;
}

export const PillButton = (props: PillButtonProps) => {
	const { IconClass, text, link, openNewTab } = props;
	return (
		<a target={openNewTab ? "_blank" : "self"} href={link}>
			<div className="icon-container">
				<IconClass />
			</div>
			{text}
		</a>
	);
};

const renderPillButton = (
	index: number,
	type: SupportedPillButtonIcon,
	href?: string
) => {
	if (href) {
		let text = "Link";
		let IconClass = IoMdLink;
		switch (type) {
			case "demo":
				text = "Demo";
				IconClass = IoMdBook;
				break;
			case "github":
				text = "Github";
				IconClass = IoLogoGithub;
				break;
			case "googlePlay":
				text = "Google Play";
				IconClass = IoMdAppstore;
				break;
			case "video":
				text = "Video";
				IconClass = IoLogoYoutube;
				break;
		}
		return (
			<PillButton
				text={text}
				link={href}
				IconClass={IconClass}
				openNewTab={true}
				key={`pill_button_${type}_${index}`}
			/>
		);
	}
	return null;
};

export const PillButtonRow = (props: PillButtonRowProps) => {
	const { github, googlePlay, demo, video } = props;
	const buttons = ([
		["github", github],
		["googlePlay", googlePlay],
		["demo", demo],
		["video", video],
	] as [SupportedPillButtonIcon, string][])
		.map((val, index) => renderPillButton(index, ...val))
		.filter((el) => el !== null);
	if (buttons.length > 0) {
		return <div className="pill-button-row">{buttons}</div>;
	}
	return null;
};
